import React from "react"
import SEO from "src/components/shared/seo"
import Layout, { Inner } from "src/components/shared/Layout"
import ContactForm from "src/components/shared/ContactForm"
import styles from "./index.styles.module.css"
import Services from "../../components/shared/Services"
import Title from "../../components/shared/Title"

const ServicesPage = ({ services }) => (
  <Layout bannerImage="lotus">
    <SEO title="Planning — Turtle Tonic — Aquabike • Aquagym • Leçon de natation" />

    <Inner id="services">
      <Title>Les cours</Title>
      <div className={styles.Services}>
        <Services />
      </div>
    </Inner>

    <div className={styles.ContactForm}>
      <Inner small id="contact">
        <ContactForm />
      </Inner>
    </div>
  </Layout>
)

export default ServicesPage
